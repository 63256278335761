import * as Sentry from "@sentry/react";
import NewPassword from 'app/modules/Auth/NewPassword';
import ResetPassword from 'app/modules/Auth/ResetPassword';
import Signup from 'app/modules/Auth/Signup';
import Unlock from 'app/modules/Auth/Unlock';
import { useAuth } from "app/providers/AuthProvider";
import React, { Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
// import AppRoutes2 from './AppRoutes2';
import { ImageViewerProvider } from "app/providers/ImageViewerProvider";
import Login from '../modules/Auth/Login';
import { ApplicationContext, CRSObject } from '../providers/ApplicationProvider';
import UppyWrapper from '../services/UppyWrapper';
import RequireAuthentication from "./RequireAuthentication";
import RequireUnauthentication from "./RequireUnauthentication";
import { AUTH, HOME, LOGIN, NEW_PASSWORD, RESET_PASSWORD, ROOT, SIGNUP, UNLOCK_USER } from './paths';


const AppRoutes2 = React.lazy(() => import('./AppRoutes2'));

export const INITIAL_CRS = {
  id: 4326,
  name: 'WGS 84',
  proj4text: '+proj=longlat +datum=WGS84 +no_defs',
  kind: 'geographic 2D',
}

let isAnyUploadInProgressValue = null;
type fn = () => any;

const LoadingPage = React.memo(() => <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>Carregando...</div>);

const Root = ({ loading = false, setCurrentAccountId}) => {
  const uppyReferences = useRef({});
  const [currentProj4Out, setCurrentProj4Out] = React.useState<CRSObject>({ ...INITIAL_CRS });
  const [currentCameraCenter, setCurrentCameraCenter] = React.useState<mapboxgl.LngLatLike>([0,0]);
  const [showSelectCRSModal, setShowSelectCRSModal] = React.useState<boolean>(false);
  const [permissions, setPermissions] = React.useState<any[]>([]);
  const history = useHistory();
  const {account} = useAuth();

  // useEffect(() => {
  // }, []);

  useEffect(() => {
    // let subs = null;
    const unsubs = history.listen((location, action) => {
      const url = `${location.pathname}${location.search}${location.hash}`;
      // console.log(`location: ${url} - action: ${action}`);
      Sentry.addBreadcrumb({
        category: "history",
        message: `location: ${url} - action: ${action}`,
        level: Sentry.Severity.Info,
      });
    });
    return () => unsubs && unsubs()
  }, []);

  const uploadInProgress = (uppy) => {
    const {files} = uppy.getState();
    const filesArray = Object.keys(files).map(file => files[file])
    const uploadStartedFiles = filesArray.filter(file => file.progress.uploadStarted)
    const pausedFiles = uploadStartedFiles.filter(file => file.isPaused)
    const inProgressFiles = filesArray.filter((file) => {
      return !file.progress.uploadComplete &&
        file.progress.uploadStarted
    })
    const isAllPaused = inProgressFiles.length !== 0 &&
      pausedFiles.length === inProgressFiles.length;

    return inProgressFiles.length > 0 && !isAllPaused;
  }

  const isAnyUploadInProgress = () => {
    if (isAnyUploadInProgressValue !== null) return isAnyUploadInProgressValue;
    isAnyUploadInProgressValue = Object.values(uppyReferences.current).reduce((prev, curr: UppyWrapper) => {
      if (prev) return prev;
      return uploadInProgress(curr.uppy);
    }, false);
    return isAnyUploadInProgressValue;
  }

  const pauseAnyUpload = () => {
    Object.values(uppyReferences.current).forEach((uppyWrapper: UppyWrapper) => {
      if (uploadInProgress(uppyWrapper.uppy)) {
        uppyWrapper.uppy.pauseAll();
      }
    })
  }

  const handleUnloadPage = (ev: Event) => {
    if (isAnyUploadInProgress()) {
      pauseAnyUpload();
    }
  }

  const handleOffline = (ev: Event) => {
    if (isAnyUploadInProgress()) {
      pauseAnyUpload();
    }
  }

  const handleBeforeUnloadPage = (ev: BeforeUnloadEvent) => {
    setTimeout(() => { /* console.log("CLEAN isAnyUploadInProgressValue"); */ isAnyUploadInProgressValue = null }, 200);
    if (isAnyUploadInProgress()) {
      ev.preventDefault();
      ev.returnValue = "O envio de imagens está em andamento. Deseja cancelar o envio e sair?";
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadPage);
    window.addEventListener("unload", handleUnloadPage);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnloadPage);
      window.removeEventListener("unload", handleUnloadPage);
      window.removeEventListener("offline", handleOffline);
    }
  }, []);

  if (loading) {
    return <LoadingPage />
  }

  return (
    <ApplicationContext.Provider value={{
      uppyReferences,
      currentProj4Out,
      setCurrentProj4Out,
      currentCameraCenter,
      setCurrentCameraCenter,
      showSelectCRSModal,
      setShowSelectCRSModal,
      permissions,
      setPermissions
    }}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route
            path={AUTH}
            render={() => (
              <RequireUnauthentication>
                <Switch>
                  <Route exact path={LOGIN}>
                    <Login />
                  </Route>
                  <Route exact path={SIGNUP}>
                    <Signup />
                  </Route>
                  <Route exact path={UNLOCK_USER}>
                    <Unlock />
                  </Route>
                  <Route exact path={RESET_PASSWORD}>
                    <ResetPassword />
                  </Route>
                  <Route exact path={NEW_PASSWORD}>
                    <NewPassword />
                  </Route>
                  <Route exact path={AUTH} render={() => <Redirect to={LOGIN} />} />
                </Switch>
              </RequireUnauthentication>
            )}
          />
          <Route
            path={ROOT}
            render={(props) => {
              const { location } = props;
              // eslint-disable-next-line compat/compat
              const queryParams = new URLSearchParams(location.search);
              const from = queryParams.get('next') || location;

              if (loading) {
                return <LoadingPage />
              }

              return (
                <RequireAuthentication redirectTo={LOGIN} from={from}>
                  <ImageViewerProvider>
                    {account && <Route path={HOME} render={() => <AppRoutes2 setCurrentAccountId={setCurrentAccountId} />} />}
                  </ImageViewerProvider>
                  {account && <Route exact path={ROOT} render={() => <Redirect to={`/${account.id}`} />} />}
                </RequireAuthentication>
              )
            }}
          />
        </Switch>
      </Suspense>
    </ApplicationContext.Provider>
  );
}
export default Root;
