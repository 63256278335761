import useFirebase from "app/hooks/useFirebase";
import React from 'react';
import { Redirect } from "react-router";

function RequireAuthentication({ children, redirectTo, from }) {
  const { isLogged, authLoading } = useFirebase();

  if (authLoading) {
    return null;
  }

  return isLogged ? children : <Redirect
    to={{
      pathname: redirectTo,
      state: { from },
    }}
  />;
}

export default RequireAuthentication;
