import Config from 'app/config';
import { CheckboxLoginOverrides } from 'app/themes';
import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { validate as validateEmail } from 'email-validator';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

declare var grecaptcha: any;
// const Field = Form.Item;

export interface FormError {
  message: string;
}

const LoginForm = props => {
  const { onSubmit, errors, showCaptcha } = props;
  const locale = (useContext(LocaleContext) as any).login;
  const [css, theme] = useStyletron();
  const recaptchaRef = useRef<HTMLDivElement>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaWidgetRef = useRef(null);
  const [isValid, setIsValid] = useState({email: false, password: false});
  const [isVisited, setIsVisited] = useState({ email: false, password: false });
  const [isLoading, setIsLoading] = useState(false);
  const shouldShowError = useCallback((key) => !isValid[key] && isVisited[key], [isValid, isVisited]);
  const disabledButton = !email || !password || (showCaptcha && !captchaToken);

  const captchaErrorCallback = () => {
    setCaptchaToken(null);
    alert(locale.errorValidation)
  }

  useEffect(() => {
    if (showCaptcha && recaptchaRef.current) {
      grecaptcha.enterprise.ready(function () {
        if (captchaWidgetRef.current === null) {
          captchaWidgetRef.current = grecaptcha.enterprise.render(recaptchaRef.current, {
            sitekey: Config.RECAPTCHA_SITEKEY,
            action: 'login',
            callback: setCaptchaToken,
            'expired-callback': () => setCaptchaToken(null),
            'error-callback': captchaErrorCallback
          })
        }
      });
    }
  }, [showCaptcha, recaptchaRef.current]);

  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
      if (captchaWidgetRef.current !== null) {
        grecaptcha.enterprise.reset(captchaWidgetRef.current);
      }
    }
  }, [errors]);

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({...old, email: validateEmail(value)}));
    setEmail(value);
  };

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, password: !!value }));
    setPassword(value);
  };

  const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log((e.target as HTMLFormElement));
    onSubmit({email, password, rememberMe, captchaToken})
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.accountAccess}</p>
      <FormControl
        label={() => {locale.enterEmail}}
        error={shouldShowError('email') ? locale.formatEmail: null}
      >
        <Input
          type="email"
          placeholder={locale.enterEmail}
          autoFocus
          value={email}
          onChange={onChangeEmail}
          onBlur={() => setIsVisited(old => ({...old, email: true})) }
          error={shouldShowError('email')}
          overrides={{
          Root: {
            style: {
              backgroundColor: 'transparent'
            }
          },
          InputContainer: {
            style: {
              backgroundColor: 'transparent'
            }
          }
        }}/>
      </FormControl>
      <FormControl
        label={() => {locale.enterPassword}}
        error={shouldShowError('password') ? locale.formatPassword: null}
      >
        <Input
          type="password"
          placeholder={locale.enterPassword}
          onBlur={() => setIsVisited(old => ({ ...old, password: true }))}
          onChange={onChangePassword}
          error={shouldShowError('password')}
          overrides={{
          Root: {
            style: {
              backgroundColor: 'transparent'
            }
          },
          InputContainer: {
            style: {
              backgroundColor: 'transparent'
            }
          }
        }}/>
      </FormControl>
      <FormControl>
        <Checkbox
          overrides={CheckboxLoginOverrides}
          checked={rememberMe}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          {locale.remember}
        </Checkbox>
      </FormControl>
      {showCaptcha && <div id="recaptcha" ref={recaptchaRef} className={css({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      })}></div>}
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={disabledButton}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {locale.enter}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({$theme}) => {
  return {
    width: '360px'
  }
});

export default LoginForm;
