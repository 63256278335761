import useFirebase from 'app/hooks/useFirebase';
import { AuthStatus } from 'app/providers/AuthProvider';
import { HOME, LOGIN, RESET_PASSWORD } from 'app/routes/paths';
import { styled } from 'baseui';
import { StyledBody } from 'baseui/card';
import { LocaleContext } from 'baseui/esm/locale/index';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import jwt_decode from 'jwt-decode';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { Link, Redirect, generatePath, useHistory, useLocation } from 'react-router-dom';
import { maplyLogo as MaplyLogo } from '../components/Icons';

const Auth = ({ children, login = false, signup = false, send_reset = false }) => {
  const { authState, token } = useFirebase();
  const locale = (useContext(LocaleContext) as any).login;
  const location = useLocation();
  const history = useHistory();

  if (authState === AuthStatus.Logged && token) {
    const decodedToken: any = jwt_decode(token);
    const homePath = generatePath(HOME, { account_id: decodedToken.account_id });
    let to = get(location, 'state.from', homePath);
    if (to.pathname && to.pathname === "/") {
      to = homePath;
    }
    return <Redirect to={to} />;
  }

  return (
    <ToasterContainer
      placement={PLACEMENT.top}
      overrides={{
        Root: {
          style: {
            zIndex: 9
          }
        },
        ToastBody: {
          style: ({ $theme }) => {
            return {
              width: "auto"
            };
          }
        },
        ToastCloseIcon: {
          style: ({ $theme }) => {
            return {
              marginLeft: "20px"
            };
          }
        }
      }}
    >
      <Wrapper>
        <Container>
          <Card>
            <Contents>
              <StyledBody>
                <Logo><MaplyLogo size={140} /></Logo>
                {children}
              </StyledBody>
            </Contents>
            {login && <BottomBar>
              <div><Link to={RESET_PASSWORD}>{locale.forgotPassword}</Link></div>
              <div>{locale.new} <a href="https://contact.maply.io/formulario">{locale.contact}</a></div>
            </BottomBar>}
            {signup && <BottomBar>
              <div>{locale.hasAccount} <Link to={LOGIN}>{locale.clickHere}</Link></div>
            </BottomBar>}
            {send_reset && <BottomBar>
              <div>{locale.hasAccount} <Link to={LOGIN}>{locale.clickHere}</Link></div>
            </BottomBar>}
          </Card>
        </Container>
        <Footer>
          <div>®2023 Maply Tecnologia. {locale.rights}</div>
          <div><a href="https://www.maply.io/contato">{locale.policy}</a> | <a href="https://www.maply.io/contato">{locale.terms}</a></div>
        </Footer>
      </Wrapper>
    </ToasterContainer>
  );
}

const Wrapper = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    minHeight: '100vh',
    height: '100%',
    backgroundColor: $theme.colors.background,
    flexDirection: 'column'
  }
});

const Container = styled('div', ({$theme}) => {
  return {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // minHeight: '600px',
    height: 'auto',
    backgroundColor: $theme.colors.background
  }
});

const Card = styled('section', ({$theme}) => {
  return {
    borderLeftWidth: '1px',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderLeftStyle: 'solid',
    borderTopStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftColor: $theme.colors.borderOpaque,
    borderRightColor: $theme.colors.borderOpaque,
    borderTopColor: $theme.colors.borderOpaque,
    borderBottomColor: $theme.colors.borderOpaque,
    // borderTopLeftRadius: $theme.borders.radius400,
    // borderTopRightRadius: $theme.borders.radius400,
    // borderBottomLeftRadius: $theme.borders.radius400,
    // borderBottomRightRadius: $theme.borders.radius400,
    backgroundColor: $theme.colors.backgroundPrimary,
    overflow: 'hidden',
  }
});

const Contents = styled('div', ({$theme}) => {
  return {
    marginLeft: '50px',
    marginTop: '50px',
    marginRight: '50px',
    marginBottom: '50px',
  }
});

const Body = styled('div', ({$theme}) => {
  return {
    marginBottom: $theme.sizing.scale600,
    color: $theme.colors.contentPrimary,
    ...$theme.typography.ParagraphMedium,

  }
});

const Logo = styled('div', ({$theme}) => {
  return {
    display: 'block',
    margin: '0px auto 40px',
    textAlign: 'center'
  }
});

const BottomBar = styled('div', ({$theme}) => {
  return {
    width: '100%',
    height: '75px',
    backgroundColor: ($theme.colors as any).avatarBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

const Footer = styled('div', ({ $theme }) => {
  return {
    width: '100%',
    height: '44px',
    backgroundColor: $theme.colors.background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 44px',
    marginBottom: '24px',
    flexDirection: 'column'
  }
});

export default Auth;
