import { gql, useMutation } from '@apollo/client';
import { LocaleContext } from 'baseui/esm/locale/index';
import { toaster } from 'baseui/toast';
import get from 'lodash/get';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import captureException from 'utils/captureException';
import LoginForm from '../../components/Login/Form';
import Auth from '../../containers/Auth';
import {
  LoginPayload,
  MutationError, MutationLoginArgs
} from '../../priv/graphql';
import { useAuth } from '../../providers/AuthProvider';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!, $captchaToken: String = "") {
    login(email: $email, password: $password, captchaToken: $captchaToken) {
      # jwt
      user {
        # __typename
        id
        name
        email
        # token
        # avatarId

        # timeZone
        # language
        # coordinateSystem
        # systemUnit
        # inclination_type
        # industry
        # tags
      }

      successful
      errors {
        message
        path
      }
    }
  }
`;

const Login = () => {
  const { status, login } = useAuth();
  const [showCaptcha, setShowCaptcha] = useState(false);
  const locale = (useContext(LocaleContext) as any).login;
  const history = useHistory();

  const [loginMutation, loginResult] = useMutation<{ login: LoginPayload, token: string },MutationLoginArgs>(LOGIN_MUTATION, {
    // onCompleted: ({ login: result, token: token }) => {
    //   console.log('After onCompleted HERE');
    //   const { user, errors, successful } = result;
    //   if (successful) {
    //     console.log('Login with', { user });
    //     login({ token: token });
    //   } else {
    //     console.error('Login mutation Errors', errors);
    //   }
    // },
  });
  const { data, error } = loginResult;

  const handleSubmit = ({email, password, rememberMe, captchaToken = null}) => {
    loginMutation({ variables: { email, password, captchaToken } }).then(({ data: { login: { user, errors, successful }, token}}) => {
      if (successful) {
        // console.log('Login with', { user });
        return login({ inputToken: token, rememberMe });
      }
        // console.error('Login mutation Errors', errors);
      setShowCaptcha(true);
      let msg;
      if (errors[0].message === "user_locked") {
        msg = locale.errorLocked;
      } else if (["invalid_user_or_password", "no_account"].includes(errors[0].message)) {
        msg = locale.errorCredentials;
      } else {
        msg = locale.errorInternal;
      }
      toaster.negative(
        <>
          {msg}
        </>,
        {
          autoHideDuration: 5000,
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
      return null;
      // console.log('After Promise then HERE', result);
    }).catch(error => {
      // console.log('After Promise catch HERE', error);
      captureException(error);
      toaster.negative(
        <>
          {locale.errorConnection}
        </>,
        {
          autoHideDuration: 5000,
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
    });
  };

  // TODO: use mutationErrors instead
  const errors: [MutationError] = error
    ? [error]
    : get(data, 'login.errors', []);

  useLayoutEffect(() => {
    if (history?.location?.state?.message) {
      toaster.info(
        <>{history?.location?.state?.message}</>,
        {
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
    }
    return () => {
      console.log('end');
    };
  }, [])

  return (
    <Auth login>
      <LoginForm onSubmit={handleSubmit} errors={errors} showCaptcha={showCaptcha} />
    </Auth>
  );
};

export default Login;
