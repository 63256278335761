import { bbox, Feature, feature, featureCollection, Geometry, point } from '@turf/turf';
import { createActionCreator, createReducer } from 'deox';
import { assoc, dissoc, dissocPath, evolve, isEmpty, mergeDeepLeft, omit, without } from 'ramda';

export type MapLayer = {
  id: string;
  processing_id: string;
  dimension_type: "two_d" | "three_d" | "any";
  layer_type?: "orthomosaic" | "dsm" | "dtm" | "dem" | "mapping_images" | "inspection_images" | "marker_images" | "marker_gcps" | "land_xml" | "design_layers" | "contour_lines";
  format_type?: "raster" | "vector";
  data: any;
  visible?: boolean;
  opacity?: number;
  highResolution?: boolean;
  bounds?: [number, number, number, number];
  tileSize?: number;
  min?: number;
  max?: number;
  step?: number;
  selectedSourceIndex?: number;
  selectedSourceIndexes?: number[];
  sources?: MapLayerSource[];
  useMapboxLayer?: boolean;
  calculate?: any;
  displayText?: boolean;
  occlusion?: boolean;
  wireframe?: boolean;
  color?: string;
  colormap?: string;
  resamplingMethod?: 'nearest' | 'bilinear' | 'cubic' | 'cubic_spline' | 'lanczos' | 'average' | 'mode' | 'gauss',
  proj4text?: string;
  srid?: number;
}

export type MapLayerSource = {
  id: string,
  url: string
}

export type Gcp = {
  coordinate: { lat: number, lng: number }
  altitude: number;
}

export type AnnotationType = 'marker' | 'polyline' | 'polygon';
export type CreatedAnnotationTYpe = { type: AnnotationType, feature: GeoJSON.Feature };

export type Annotation = {
  id: string;
  title: string;
  processingId: string;
  projectId: string;
  type: string;
  geometry: GeoJSON.Geometry;
  color: string;
  folderId: string;
  metadata: {
    sourceId: string;
    elevation?: number[];
    elevations?: number[];
  }
}

export interface AnnotationProfile {
  annotationId: string;
  processingId: string;
  points: Array<[number, number, number]>;
  inclinations: {
    degrees: number[],
    radians: number[],
    grading: number[]
  },
  error: string
}

export type MapContainerState = {
  byId: { [id: string]: MapLayer }
  allIds: string[];
  currentUploadStoreId: string;
  mapBounds: MapBounds;
  mapLoaded: boolean;
  inTransition: boolean;
  secondaryMapIsOn: boolean;
  secondaryMapData: {
    byId: { [id: string]: MapLayer }
    allIds: string[];
  }
  annotations: Annotation[];
  annotationQuery: string;
  selectedAnnotationFolder: string;
  selectedAnnotation: string;
  cropAnnotation: Feature;
  showAnnotationsControl: boolean;
  creatingAnnotationType: AnnotationType,
  createdAnnotation: CreatedAnnotationTYpe,
  currentAnnotationProfilesData: {
    profiles: AnnotationProfile[];
    length: number;
  },
  editingSelectedAnnotation: boolean;
  goToAnnotation: string;
  referenceMapbox: boolean;
}

export type MapBounds = {
  bounds: number[];
  padding: boolean;
  force?: boolean;
};

// CONSTANTS
export const SET_LAYERS = 'SET_LAYERS';
export const ADD_LAYER = 'ADD_LAYER';
export const CHANGE_LAYER = 'CHANGE_LAYER';
export const REMOVE_LAYER = 'REMOVE_LAYER';
export const ADD_SECONDARY_LAYER = 'ADD_SECONDARY_LAYER';
export const REMOVE_SECONDARY_LAYER = 'REMOVE_SECONDARY_LAYER';
export const CLEAR_SECONDARY_LAYERS = 'CLEAR_SECONDARY_LAYERS';
export const SET_CURRENT_UPLOAD_STORE_ID = 'SET_CURRENT_UPLOAD_STORE_ID';
export const SET_SCREEN_BOUNDS = 'SET_SCREEN_BOUNDS';
export const CLEAR_LAYERS = 'CLEAR_LAYERS';
export const RESET_LAYERS = 'RESET_LAYERS';
export const SHOW_SECONDARY_MAP = 'SHOW_SECONDARY_MAP';
export const HIDE_SECONDARY_MAP = 'HIDE_SECONDARY_MAP';
export const SET_IN_TRANSITION = 'SET_IN_TRANSITION';
export const SET_REFERENCE_MAPBOX = 'SET_REFERENCE_MAPBOX';
export const MAP_LOADED = 'MAP_LOADED';
export const SHOW_ANNOTATIONS_CONTROL = 'SHOW_ANNOTATIONS_CONTROL';
export const CHANGE_CREATING_ANNOTATION_TYPE = 'CHANGE_CREATING_ANNOTATION_TYPE';
export const CHANGE_CREATED_ANNOTATION = 'CHANGE_CREATED_ANNOTATION';
export const CHANGE_ANNOTATIONS = 'CHANGE_ANNOTATIONS';
export const CHANGE_ANNOTATIONS_QUERY = 'CHANGE_ANNOTATIONS_QUERY';
export const CHANGE_SELECTED_ANNOTATIONS_FOLDER_ID = 'CHANGE_SELECTED_ANNOTATIONS_FOLDER_ID';
export const CHANGE_ANNOTATION_PROFILES = 'CHANGE_ANNOTATION_PROFILES';
export const CHANGE_SELECTED_ANNOTATION_ID = 'CHANGE_SELECTED_ANNOTATION_ID';
export const EDIT_SELECTED_ANNOTATION = 'EDIT_SELECTED_ANNOTATION';
export const GOTO_SELECTED_ANNOTATION = 'GOTO_SELECTED_ANNOTATION';

// export const ADD_ANNOTATION = 'ADD_ANNOTATION';
// export const REMOVE_ANNOTATION = 'REMOVE_ANNOTATION';

export const INITIALIZE_CROP_ANNOTATION = 'INITIALIZE_CROP_ANNOTATION';
export const FINISH_CROP_ANNOTATION = 'FINISH_CROP_ANNOTATION';
export const CROP_ANNOTATION_CHANGED = 'CROP_ANNOTATION_CHANGED';

// to delete
export const EXCLUSIVE_LAYER_SELECTION = 'EXCLUSIVE_LAYER_SELECTION'

// ACTIONS
export const setDesignLayersAction = createActionCreator(SET_LAYERS, resolve => (layers: MapLayer[]) => resolve(layers));
export const addLayerAction = createActionCreator(ADD_LAYER, resolve => (layer: MapLayer) => resolve(layer));
export const removeLayerAction = createActionCreator(REMOVE_LAYER, resolve => (id: string) => resolve(id));
export const addSecondaryLayerAction = createActionCreator(ADD_SECONDARY_LAYER, resolve => (layer: MapLayer) => resolve(layer));
export const removeSecondaryLayerAction = createActionCreator(REMOVE_SECONDARY_LAYER, resolve => (id: string) => resolve(id));
export const changeLayerAction = createActionCreator(CHANGE_LAYER, resolve => (id: string, layer: Omit<Partial<MapLayer>, 'id'>, meta?: { exclusive: boolean }) => resolve({ [id]: { ...layer } }, meta));
export const clearLayersAction = createActionCreator(CLEAR_LAYERS, resolve => () => resolve());
export const resetLayersAction = createActionCreator(RESET_LAYERS, resolve => (layers: any[]) => resolve(layers));
export const clearSecondaryLayersAction = createActionCreator(CLEAR_SECONDARY_LAYERS, resolve => () => resolve());
export const setCurrentUploadStoreIdAction = createActionCreator(SET_CURRENT_UPLOAD_STORE_ID, resolve => (uploadStoreId: string) => resolve(uploadStoreId));
export const setScreenBounds = createActionCreator(SET_SCREEN_BOUNDS, resolve => (bounds: MapBounds) => resolve(bounds));
export const showSecondaryMap = createActionCreator(SHOW_SECONDARY_MAP, resolve => () => resolve());
export const hideSecondaryMap = createActionCreator(HIDE_SECONDARY_MAP, resolve => () => resolve());
export const setInTransition = createActionCreator(SET_IN_TRANSITION, resolve => (inTransition: boolean) => resolve(inTransition));
export const setReferenceMapbox = createActionCreator(SET_REFERENCE_MAPBOX, resolve => (referenceMapbox: boolean) => resolve(referenceMapbox));
export const setMapLoaded = createActionCreator(MAP_LOADED, resolve => (loaded: boolean) => resolve(loaded));
export const showAnnotationsControl = createActionCreator(SHOW_ANNOTATIONS_CONTROL, resolve => (show: boolean) => resolve(show));
// export const changeCreatingAnnotationType = createActionCreator(CHANGE_CREATING_ANNOTATION_TYPE, resolve => (annotationType: AnnotationType) => resolve(annotationType));
// export const changeCreatedAnnotation = createActionCreator(CHANGE_CREATED_ANNOTATION, resolve => (payload: CreatedAnnotationTYpe) => resolve(payload));
// export const changeAnnotationProfiles = createActionCreator(CHANGE_ANNOTATION_PROFILES, resolve => (payload: {length: number, profiles: AnnotationProfile[]}) => resolve(payload));
// export const changeAnnotations = createActionCreator(CHANGE_ANNOTATIONS, resolve => (payload: any[]) => resolve(payload));
// export const changeAnnotationsQuery = createActionCreator(CHANGE_ANNOTATIONS_QUERY, resolve => (payload: string) => resolve(payload));
// export const changeSelectedAnnotationsFolder = createActionCreator(CHANGE_SELECTED_ANNOTATIONS_FOLDER_ID, resolve => (payload: string) => resolve(payload));
// export const changeSelectedAnnotation = createActionCreator(CHANGE_SELECTED_ANNOTATION_ID, resolve => (payload: string) => resolve(payload));
// export const editSelectedAnnotation = createActionCreator(EDIT_SELECTED_ANNOTATION, resolve => (payload: {editing: boolean, id?: string }) => resolve(payload));
// export const goToSelectedAnnotation = createActionCreator(GOTO_SELECTED_ANNOTATION, resolve => (payload: string) => resolve(payload));

// export const addAnnotation = createActionCreator(ADD_ANNOTATION, resolve => (feature: Feature) => resolve(feature));
// export const removeAnnotation = createActionCreator(REMOVE_ANNOTATION, resolve => (id: string) => resolve(id));

export const initializeCropAnnotation = createActionCreator(INITIALIZE_CROP_ANNOTATION, resolve => (feature: Feature) => resolve(feature));
export const finishCropAnnotation = createActionCreator(FINISH_CROP_ANNOTATION, resolve => () => resolve());
export const cropAnnotationChanged = createActionCreator(CROP_ANNOTATION_CHANGED, resolve => (feature: Feature) => resolve(feature));

// to delete
export const layerSelectionAction = createActionCreator(EXCLUSIVE_LAYER_SELECTION, resolve => (id: string) => resolve(id));

// INITIAL_STATE
const initialState: MapContainerState = {
  byId: {},
  allIds: [],
  currentUploadStoreId: null,
  mapBounds: {
    bounds: null,
    padding: true,
  },
  mapLoaded: false,
  secondaryMapIsOn: false,
  inTransition: false,
  secondaryMapData: {
    byId: {},
    allIds: [],
  },
  annotations: [],
  cropAnnotation: null,
  showAnnotationsControl: false,
  creatingAnnotationType: null,
  createdAnnotation: null,
  annotationQuery: '',
  selectedAnnotationFolder: null,
  currentAnnotationProfilesData: {
    profiles: null,
    length: 0
  },
  selectedAnnotation: null,
  editingSelectedAnnotation: false,
  goToAnnotation: null,
  referenceMapbox: false,
};

export const createFeatureCollection = (coordinates) => {
  return featureCollection(coordinates.map(coord => point([coord.lng, coord.lat], { status: 'queued' })))
}

const mapContainerReducer = createReducer(initialState, handleAction => [
  handleAction(setDesignLayersAction, (state, { payload }) => {
    const stateDesignLayersIds = state.allIds.filter(id => id.indexOf('design_layers') === 0);
    // const payloadDesignLayersIds = payload.map(item => item.id);
    // const stateToDelete = stateDesignLayersIds.filter(id => !payloadDesignLayersIds.some(id2 => id2 === id));
    const newById = omit(stateDesignLayersIds, state.byId);
    const newAllIds = without(stateDesignLayersIds, state.allIds);
    payload.forEach(item => {
      newById[item.id] = item;
      newAllIds.push(item.id)
    })
    // console.log(payload.map(item => item.data),newById);
    return {
      ...state,
      byId: newById,
      allIds: newAllIds
    };
  }),
  handleAction(addLayerAction, (state, { payload }) => {
    let bounds, resamplingMethod, tileSize;
    let newPayload = { ...payload, useMapboxLayer: false, opacity: payload.opacity || 100 };
    if (payload.format_type === 'raster') {
      resamplingMethod = payload.data.resamplingMethod || 'average';
      tileSize = payload.data.tileSize || 512;
      newPayload = { ...newPayload, resamplingMethod: resamplingMethod, tileSize: tileSize }
      if (payload.layer_type === 'dsm') {
        newPayload = { ...newPayload, min: payload.data.histogram.min, max: payload.data.histogram.max }
      }
    } else if (payload.dimension_type !== 'three_d' && payload.layer_type !== 'land_xml' && payload.layer_type !== 'design_layers') {
      newPayload.data = (payload.data?.features ? payload.data : createFeatureCollection(payload.data || []));
      newPayload.data = { type: 'FeatureCollection', features: newPayload.data.features.filter(feature => !!feature.geometry) };
      bounds ||= bbox(newPayload.data);
    }
    newPayload = { ...{ visible: false }, ...newPayload }

    return {
      ...state,
      byId: assoc(payload.id, newPayload, state.byId),
      allIds: (state.allIds.indexOf(payload.id) > -1 ? [...state.allIds] : [...state.allIds, payload.id]),
    }
  }),
  handleAction(addSecondaryLayerAction, (state, { payload }) => {
    let bounds, resamplingMethod, tileSize;
    let newPayload = { ...payload, useMapboxLayer: false, opacity: payload.opacity || 100 };
    if (payload.format_type === 'raster') {
      resamplingMethod = payload.data.resamplingMethod || 'average';
      tileSize = payload.data.tileSize || 512;
      newPayload = { ...newPayload, resamplingMethod: resamplingMethod, tileSize: tileSize }
      if (payload.layer_type === 'dsm') {
        const primaryDSM = state.byId['dsm'];
        newPayload = { ...newPayload, min: (primaryDSM || payload.data.histogram).min, max: (primaryDSM || payload.data.histogram).max }
      }
    } else if (payload.dimension_type !== 'three_d') {
      newPayload.data = payload.data?.features ? payload.data : createFeatureCollection(payload.data || []);
      newPayload.data = { type: 'FeatureCollection', features: newPayload.data.features.filter(feature => !!feature.geometry) };
      bounds ||= bbox(newPayload.data);
    }
    newPayload = { ...{ visible: false }, ...newPayload }

    return {
      ...state,
      secondaryMapData: {
        byId: assoc(payload.id, newPayload, state.secondaryMapData.byId),
        allIds: (state.secondaryMapData.allIds.indexOf(payload.id) > -1 ? [...state.secondaryMapData.allIds] : [...state.secondaryMapData.allIds, payload.id]),
      }
    }
  }),
  handleAction(removeLayerAction, (state, { payload }) => ({
    ...state,
    byId: dissoc(payload, state.byId),
    allIds: without([payload], state.allIds),
  })),
  handleAction(removeSecondaryLayerAction, (state, { payload }) => ({
    ...state,
    secondaryMapData: {
      byId: dissoc(payload, state.secondaryMapData.byId),
      allIds: without([payload], state.secondaryMapData.allIds),
    }
  })),
  handleAction(changeLayerAction, (state, { payload, meta }) => {
    const layerKey = Object.keys(payload)[0];
    if (state.allIds.indexOf(layerKey) >= 0) {
      let main = evolve({ byId: mergeDeepLeft(payload) }, state);
      if (payload[layerKey].hasOwnProperty('data') && isEmpty(payload[layerKey]['data'])) {
        main.byId[layerKey]['data'] = payload[layerKey]['data'];
      }
      if (payload[layerKey].hasOwnProperty('selectedSourceIndex')) {
        main.byId[layerKey]['selectedSourceIndex'] = payload[layerKey]['selectedSourceIndex'];
      }
      if (state.secondaryMapData.allIds.indexOf(layerKey) >= 0) {
        const new_payload = dissocPath([layerKey, 'processing_id'], payload);
        main.secondaryMapData = evolve({ byId: mergeDeepLeft(new_payload) }, main.secondaryMapData);
      }
      if (meta?.exclusive) {
        const others = state.allIds.filter(id => layerKey !== id).reduce((prev, curr) => {
          prev[curr] = { visible: false };
          return prev;
        }, {});
        main = evolve({ byId: mergeDeepLeft(others) }, main);
      }
      return main;
    } else {
      return state;
    }
  }),
  handleAction(clearLayersAction, (state, { }) => ({
    ...state,
    byId: {},
    allIds: [],
  })),
  handleAction(clearSecondaryLayersAction, (state, { }) => ({
    ...state,
    secondaryMapData: {
      byId: {},
      allIds: [],
    }
  })),
  handleAction(showSecondaryMap, (state, { }) => ({
    ...state,
    secondaryMapIsOn: true,
  })),
  handleAction(hideSecondaryMap, (state, { }) => ({
    ...state,
    secondaryMapIsOn: false,
    secondaryMapData: {
      byId: {},
      allIds: [],
    }
  })),
  handleAction(setCurrentUploadStoreIdAction, (state, { payload }) => ({
    ...state,
    secondaryMapIsOn: false,
  })),
  handleAction(resetLayersAction, (state, { payload }) => {
    for (const layer in payload) {
      if (state.byId[layer]) {
        state.byId[layer].visible = payload[layer].selected
        if ( state.byId[layer].selectedSourceIndex )
          state.byId[layer].selectedSourceIndex = 0
      }
    }
    return state;
  }),
  handleAction(setScreenBounds, (state, { payload }) => {
    if (!payload)
      return { ...state, mapBounds: { bounds: null, padding: true, force: false } }
    else {
      return { ...state, mapBounds: { ...payload } }
    }
  }),
  handleAction(setInTransition, (state, { payload }) => {
    return { ...state, inTransition: payload }
  }),
  handleAction(setReferenceMapbox, (state, { payload }) => {
    return { ...state, referenceMapbox: payload }
  }),
  handleAction(setMapLoaded, (state, { payload }) => {
    return { ...state, mapLoaded: payload }
  }),
  handleAction(showAnnotationsControl, (state, { payload }) => {
    return { ...state, showAnnotationsControl: payload }
  }),
  // handleAction(changeCreatingAnnotationType, (state, { payload }) => {
  //   return { ...state, creatingAnnotationType: payload }
  // }),
  // handleAction(changeCreatedAnnotation, (state, { payload }) => {
  //   return { ...state, createdAnnotation: payload }
  // }),
  // handleAction(changeAnnotations, (state, { payload }) => {
  //   return { ...state, annotations: payload }
  // }),
  // handleAction(changeSelectedAnnotationsFolder, (state, { payload }) => {
  //   return { ...state, selectedAnnotationFolder: payload }
  // }),
  // handleAction(changeSelectedAnnotation, (state, { payload }) => {
  //   return { ...state, selectedAnnotation: payload, editingSelectedAnnotation: false }
  // }),
  // handleAction(changeAnnotationsQuery, (state, { payload }) => {
  //   return { ...state, annotationQuery: payload == null ? '' : payload }
  // }),
  // handleAction(changeAnnotationProfiles, (state, { payload }) => {
  //   return { ...state, currentAnnotationProfilesData: payload }
  // }),
  // handleAction(editSelectedAnnotation, (state, { payload }) => {
  //   return { ...state, editingSelectedAnnotation: payload.editing, selectedAnnotation: payload.id ?? state.selectedAnnotation }
  // }),
  // handleAction(goToSelectedAnnotation, (state, { payload }) => {
  //   return { ...state, goToAnnotation: payload }
  // }),
  // handleAction(addAnnotation, (state, { payload }) => {
  //   return {
  //     ...state,
  //     annotations: [...state.annotations, payload]
  //   }
  // }),
  // handleAction(removeAnnotation, (state, { payload }) => {
  //   return {
  //     ...state,
  //     annotations: state.annotations.filter(ft => ft.id !== payload)
  //   }
  // }),

  handleAction(initializeCropAnnotation, (state, { payload }) => {
    return {
      ...state,
      cropAnnotation: payload
    }
  }),
  handleAction(finishCropAnnotation, (state) => {
    return {
      ...state,
      cropAnnotation: null
    }
  }),
  handleAction(cropAnnotationChanged, (state, { payload }) => {
    return {
      ...state,
      cropAnnotation: payload
    }
  }),

  // to delete
  handleAction(layerSelectionAction, (state, { payload }) => {
    const newByIds = state.allIds.map(id => {
      const item = state.byId[id];
      item.visible = item.id === payload;
      return item;
    }).reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {})
    return { ...state }
  })
])

export default mapContainerReducer;

const filterFn = (searchText) => (item) => !searchText.trim() || item.title.trim().toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0

export const addAnnotationMetadata = (state: MapContainerState) => (annotation) => feature(annotation.geometry as Geometry, {
  color: annotation.color,
  id: annotation.id,
  processingId: annotation.processingId,
  projectId: annotation.projectId,
  complementaryData: (state.currentAnnotationProfilesData && state.currentAnnotationProfilesData.profiles && state.currentAnnotationProfilesData.profiles.length > 0 && [
    { id: 'profile', visible: true, data: { ...state.currentAnnotationProfilesData.profiles[0].points } },
    { id: 'inclinations', visible: true, data: { ...state.currentAnnotationProfilesData.profiles[0].inclinations } }
  ]) || []
})

export const getAnnotationsWithFolder = (state: MapContainerState) =>
  state.annotations.filter(item => state.selectedAnnotationFolder == item.folderId).filter(filterFn(state.annotationQuery))

export const getSecondaryMapSwitch = (state: MapContainerState) =>
  state.secondaryMapIsOn

export const getAllLayers = (state: MapContainerState) =>
  state.allIds.map(id => state.byId[id])

export const getElevationLayer = (state: MapContainerState) =>
  state.byId['dsm']

export const getCropAnnotation = (state: MapContainerState) =>
  state.cropAnnotation

export const getIfMapInTransition = (state: MapContainerState) =>
  state.inTransition

export const getIfMapLoaded = (state: MapContainerState) =>
  state.mapLoaded

export const getAllSecondaryLayers = (state: MapContainerState) =>
  state.secondaryMapData.allIds.map(id => state.secondaryMapData.byId[id])

export const getMapBounds = (state: MapContainerState) =>
  state.mapBounds

export const getReferenceMapbox = (state: MapContainerState) =>
  state.referenceMapbox

export const getCurrentAnnotationProfiles = (state: MapContainerState) =>
  state.currentAnnotationProfilesData

export const getElevationsBounds = (state: MapContainerState) =>
  state.allIds.filter(id => id === 'dsm').map(id => [state.byId[id].min, state.byId[id].max])[0]
