import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { OnChangeParams, Select } from 'baseui/select';
import { toaster } from 'baseui/toast';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// const Field = Form.Item;

export interface Props {
  token: string;
  email: string;
}

const SignUpForm = props => {
  const { onSubmit, errors } = props;
  const { industryOptions } = (useContext(LocaleContext) as any).app.components;
  const locale = (useContext(LocaleContext) as any).login;
  const [css, theme] = useStyletron();

  // const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isValid, setIsValid] = useState({ name: false, industry: false, password: false, passwordConfirmation: false });
  const [isVisited, setIsVisited] = useState({ name: false, industry: false, password: false, passwordConfirmation: false });
  const [isLoading, setIsLoading] = useState(false);
  const shouldShowError = useCallback((key) => !isValid[key] && isVisited[key], [isValid, isVisited]);
  const allValid = useMemo(() => !Object.keys(isValid).some(key => !isValid[key]), [isValid])
  const disabledButton = !(industry && name && password && allValid);
  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

  const options = Object.keys(industryOptions).map(key => ({id: key, label: industryOptions[key]}));

  // console.log(errors);

  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
      const msg = errors[0].message === "invalid_user_or_password" ? locale.errorCredentials : locale.errorInternal;
      const toastKey = toaster.info(
        <>
          {msg}
        </>,
        {
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
    }
  }, [errors]);

  const onChangeName = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, name: !!value }));
    setName(value);
  };

  const onChangeIndustry = (params: OnChangeParams) => {
    setIsValid(old => ({ ...old, industry: !!params.option }));
    setIndustry(params?.option?.id as string);
  };

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, password: strongPassword.test(value) }));
    setPassword(value);
  };

  const onChangePasswordConfirmation = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, passwordConfirmation: password === value }));
  };

  const checkBeforeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    console.log((e.target as HTMLFormElement));
    try {
      await onSubmit({ name, password, passwordConfirmation: password, industry, token: props.token })
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.register}</p>
      <FormControl
        label={() => locale.enterEmail}
        error={shouldShowError('email') ? locale.formatEmail : null}
      >
        <Input
          type="email"
          placeholder={locale.enterEmail}
          disabled
          value={props.email}
          maxLength={320}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <FormControl
        label={() => locale.enterName}
        error={shouldShowError('name') ? locale.validateName : null}
      >
        <Input
          placeholder={locale.enterName}
          onBlur={() => setIsVisited(old => ({ ...old, name: true }))}
          onChange={onChangeName}
          error={shouldShowError('name')}
          maxLength={100}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <FormControl
        label={() => locale.selectIndustry}
        error={shouldShowError('industry') ? locale.validateIndustry : null}
      >
        <Select
          options={options}
          onBlur={() => setIsVisited(old => ({ ...old, industry: true }))}
          onChange={onChangeIndustry}
          value={options.filter(opt => opt.id === industry)}
          clearable={false}
          creatable={false}
          overrides={{
            Input: {
              props: {
                readOnly: true,
              },
            },
            ControlContainer: {
              style: {
                cursor: 'pointer',
                backgroundColor: 'transparent'
              }
            }
          }}
        />
        {/* <Input
          placeholder="Selecione a Industria"
          onBlur={() => setIsVisited(old => ({ ...old, industry: true }))}
          onChange={onChangeName}
          error={shouldShowError('industry')}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} /> */}
      </FormControl>
      <FormControl
        label={() => locale.enterPassword}
        error={shouldShowError('password') ? locale.validatePassword : null}
      >
        <Input
          type="password"
          placeholder={locale.enterPassword}
          onBlur={() => setIsVisited(old => ({ ...old, password: true }))}
          onChange={onChangePassword}
          error={shouldShowError('password')}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>

      <FormControl
        label={() => locale.confirmPassword}
        error={shouldShowError('passwordConfirmation') ? locale.notEqual : null}
      >
        <Input
          type="password"
          placeholder={locale.confirmPassword}
          onBlur={() => setIsVisited(old => ({ ...old, passwordConfirmation: true }))}
          onChange={onChangePasswordConfirmation}
          error={shouldShowError('passwordConfirmation')}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={disabledButton}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {locale.registerButton}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({ $theme }) => {
  return {
    width: '360px'
  }
});

export default SignUpForm;
